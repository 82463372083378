<script setup lang="ts">
import { useGtm } from "@gtm-support/vue-gtm";
import appConfig from "~/app.config";

const { isMobile, isDesktop } = useDevice();
const route = useRoute();
const gtm = useGtm();

const { clientType, picto, title, appleStoreLink, playStoreLink, wide, sourceOrigin } =
  defineProps<{
    clientType: string;
    picto: string;
    title: string;
    appleStoreLink: string;
    playStoreLink: string;
    withBackground?: boolean;
    wide?: boolean;
    subtitle?: string;
    sourceOrigin: string;
  }>();

const userType = computed(() => {
  return clientType === "recruteur" ? "etab" : "candidates";
});

const playStoreUrl = computed(() => {
  return `${playStoreLink}&${appConfig.utm.source}${sourceOrigin}${
    appConfig.utm.campaign
  }${appConfig.utm[userType.value].mobile_app}--${route.name}`;
});

const appleStoreUrl = computed(() => {
  return `${appleStoreLink}?${appConfig.utm.source}${sourceOrigin}${
    appConfig.utm[userType.value].mobile_app
  }--${route.name}`;
});

const pushDataLayerInfos = (os: string): void => {
  gtm?.push({
    push_mobile_app: `download_mobile_app_${userType.value}_${os}`,
  });
};
</script>

<template>
  <div
    class="push-mobile-apps"
    :class="[clientType, { 'with-background': withBackground }, { wide: wide }]"
  >
    <h3 class="push-mobile-apps-title">{{ title }}</h3>
    <div class="push-mobile-apps-wrapper">
      <div class="push-mobile-apps-links">
        <div class="push-mobile-apps-links-infos">
          <NuxtImg
            class="push-mobile-apps-links-infos-picto"
            :src="picto"
            :alt="`picto app ${clientType}`"
            loading="lazy"
            :title="`picto app ${clientType}`"
          />
          <h4 v-if="isDesktop" class="push-mobile-apps-links-infos-title">
            APP {{ clientType }}
          </h4>
        </div>
        <div class="push-mobile-apps-links-cta">
          <h4 v-if="isMobile" class="push-mobile-apps-links-cta-title">
            APP {{ clientType }}
          </h4>
          <div class="push-mobile-apps-links-cta-container">
            <NuxtLink
              :to="playStoreUrl"
              target="_blank"
              rel="nofollow"
              @click="pushDataLayerInfos('android')"
            >
              <img
                loading="lazy"
                src="/img/logo-playstore.webp"
                alt="Logo playstore pour telecharger l'app Extracadabra"
              />
            </NuxtLink>
            <NuxtLink
              :to="appleStoreUrl"
              target="_blank"
              rel="nofollow"
              @click="pushDataLayerInfos('ios')"
            >
              <img
                loading="lazy"
                src="/img/logo-appstore.webp"
                alt="Logo app pour telecharger l'app Extracadabra"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
      <div v-if="subtitle" class="push-mobile-apps-subtitle">
        <p>{{ subtitle }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.push-mobile-apps {
  box-shadow: 0px 1px 20px 0px rgba(112, 112, 112, 0.15);
  border-radius: 3px;
  width: 100%;
  @include mq(desktop) {
    width: 100%;
    margin-left: 0;
  }
  &-title {
    font-weight: 800;
    text-align: center;
    line-height: 34px;
    font-size: var(--showcase-size-150);
    padding: var(--showcase-size-150) var(--showcase-size-100) var(--showcase-size-100);
    background-color: var(--showcase-color-additional-light);
    @include mq(desktop) {
      padding: 31px $gutter * 7;
      font-size: 26px;
    }
  }
  &-subtitle {
    margin-top: var(--showcase-size-100);
    color: var(--showcase-color-additional-light);
    text-align: center;
    @include mq(desktop) {
      text-align: left;
      display: none;
    }
  }
  &-wrapper {
    padding: 0 var(--showcase-size-100) var(--showcase-size-100);
    @include mq(desktop) {
      padding: 0;
    }
  }
  &-links {
    border-radius: 3px;
    display: flex;
    flex-direction: row-reverse;
    padding: var(--showcase-size-100);
    @include mq(desktop) {
      padding: var(--showcase-size-200) var(--showcase-size-200) var(--showcase-size-175);
      border-radius: 0 0 3px 3px;
      display: block;
    }
    &-infos {
      display: flex;
      align-items: center;
      color: $white;
      font-weight: 800;
      font-size: 24px;
      text-transform: uppercase;
      @include mq(desktop) {
        margin-bottom: var(--showcase-size-150);
        margin-left: $gutter * 4;
      }
      &-title {
        max-width: 120px;
        margin-left: $gutter * 4;
        line-height: 33px;
        @include mq(desktop) {
          max-width: none;
        }
      }
      &-picto {
        max-width: 65px;
      }
    }
    &-cta {
      width: 100%;
      @include mq(desktop) {
        width: auto;
      }
      &-container {
        display: flex;
        justify-content: flex-start;
        @include mq(desktop) {
          justify-content: space-around;
        }
        img {
          max-width: 105px;
          margin-right: var(--showcase-size-050);
          @include mq(desktop) {
            max-width: 136px;
            margin-right: 0;
          }
        }
      }
      &-title {
        color: $white;
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: $gutter;
      }
    }
  }
  &.candidat {
    .push-mobile-apps-title {
      color: $primary-color;
    }
    .push-mobile-apps-links {
      background-color: $primary-color-800;
    }
  }

  &.recruteur {
    .push-mobile-apps-title {
      color: var(--showcase-color-secondary-200);
    }
    .push-mobile-apps-links {
      background-color: var(--showcase-color-secondary-400);
    }
    &.with-background {
      background-color: var(--showcase-color-secondary-200);
      border-radius: 0;
      padding: 0;
      .push-mobile-apps-title {
        color: var(--showcase-color-additional-light);
        background-color: transparent;
      }
    }
  }
  &.wide {
    .push-mobile-apps-title {
      padding: var(--showcase-size-200) var(--showcase-size-200);
      height: 150px;
    }
    .push-mobile-apps-links {
      &-infos {
        justify-content: center;
      }
      &-cta {
        &-container {
          justify-content: space-evenly;
        }
      }
    }
  }
}
</style>
